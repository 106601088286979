@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');
* {
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	height: 100%;
	font-family: 'Plus Jakarta Sans', sans-serif !important;
}
body {
	overflow: auto !important;
}
html,
body {
	overflow-x: hidden !important;
	font-family: 'Plus Jakarta Sans', sans-serif !important;
}

label {
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 16px;
	font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
	color: #ff923d !important;
}

.drop {
	background: #f9f9f9;
	border-radius: 10px;
	border: 0;
	color: #838383;
	font-weight: 600;
	font-size: 15px;
	width: fit-content;
}

.circle {
	margin-right: 10px;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #3a1086;
}

.drop:hover {
	border: 0;
}
.MuiTable-root {
	padding: 10px;
}
.MuiTable-root td:first-child,
.MuiTable-root th:first-child {
	border-radius: 10px 0 0 10px;
}

.MuiTable-root td:last-child,
.MuiTable-root th:last-child {
	border-radius: 0 10px 10px 0;
}
.MuiTable-root td,
.MuiTable-root th {
	font-weight: 500;
	padding: 10px;
	border: 0 !important;
	color: #231735;
}
.iconBtnGroup button {
	border-radius: 50% !important;
	margin: 0 5px;
	border: 0 !important;
	min-height: 40px;
}
.iconBtnGroup button:hover {
	background-color: #ffffff;
	color: #40018c;
}
.iconBtnGroup button:hover img {
	filter: invert(11%) sepia(73%) saturate(7423%) hue-rotate(270deg)
		brightness(61%) contrast(112%);
}
.MuiPagination-root
	.css-1lxv4ne-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
	background-color: #c3c0c8;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters::before {
	background-color: #ffffff;
}

.MuiTooltip-tooltip {
	background-color: #3a1086;
	border-radius: 20px;
	padding: 10px;
	box-shadow: 0px 3px 20px rgba(58, 16, 134, 0.15);
	margin-top: 0 !important;
}
.MuiTooltip-tooltip.MuiBox-root {
	/* background-color: transparent; */
}
.MuiTooltip-arrow::before {
	background-color: #3a1086;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
	padding: 7px;
}
.MuiOutlinedInput-notchedOutline {
	border: 0;
}
.MuiAccordionSummary-content {
	margin: 0;
	align-items: center;
}
.MuiButtonBase-root-MuiButton-root svg path {
	stroke: #3a1086;
}
.MuiButtonBase-root-MuiButton-root rect {
	fill: transparent;
}
.Include h6,
.Nutrients .MuiTypography-root.MuiTypography-subtitle2.makeStyles-small-68 {
	opacity: 0;
}
@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
	margin: 0 !important;
}
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
